import logo from './images/pisika-web-header.png';
import background from './images/background.mp4';

import './App.css';
import * as React from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#ffffff",
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
    },
  },
});

theme.typography.cite = {
  fontSize: '12px',
  '@media (min-width:600px)': {
    fontSize: '12px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
  },
};

theme.typography.entry = {
  fontWeight: 800
};


function App() {
  return (
    <>
    <div className="App">
      <video autoPlay playsInline muted loop id="video">
        <source src={background} type="video/mp4" />
      </video>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <ThemeProvider theme={theme}>
        <a target="_self" href="https://pisika-ai.myshopify.com/password" >
          <Button className="enter-button" color="primary" variant="outlined">
            <Typography color="white" variant="entry">
              Enter
            </Typography>
          </Button>
        </a>
        </ThemeProvider>
      </header>
      <div className="citations">
        <Typography color="white" variant="cite">
          Anishmesh Karnewar & Oliver Wang. MSG-GAN: Multi-Scale Gradients for Generative Adversarial Networks. (2020) arXiv:1903.06048v4
        </Typography>
        <br/>
        <Typography color="white" variant="cite">
          Code: https://github.com/akanimax/msg-stylegan-tf
        </Typography>
      </div>
    </div>

    </>
  );
}

export default App;
